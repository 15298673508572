.layout__menu-toggle {
    display: none;
}

@media (max-width: 1490px) {
    body {
        padding: 0;
    }

    .layout {
        min-width: auto;
        width: 85%;
    }

    .logos-box__last {
        margin-bottom: 2rem;
      }

    .logos-box__imagen {
        width: 11rem;
        height: 11rem;
        margin-bottom: 3rem;
    }

    .skills__subheader--other {
        margin-top: 5.5rem;
    }
}

@media (max-width: 1380px) {
    .layout {
        width: 88%;
        margin-left: 3rem;
    }

    .gallery__imagen {
        height: 23rem;
    }

    .data__icon {
        font-size: 3rem;
        margin-bottom: 1rem;
    }

    .data__subtitle {
        font-size: 1.5rem;
    }

    .contact__subtitle {
        font-size: 1.9rem;
        margin-left: 0.5rem;
    }
}

@media (max-width: 1180px) {
    .layout {
        width: 85%;
        margin-left: 4rem;
    }

    .logos-box__imagen {
        margin-top: 1rem;
        width: 9.5rem;
        height: 9.5rem;
    }

    .skills__subheader--other {
        margin-top: 5rem;
    }
}

@media (max-width: 1099px) {
    body {
        background-image: none;
        position: static;
    }

    .layout {
        width: 100%;
        height: 100%;
        min-height: 100%;
        margin: 0;
        border-radius: 0;

        grid-template-areas: "content";
        grid-template-columns: 100%;   
    }

    .layout__menu-toggle {
        display: block;
        position: absolute;
        top: 1.5rem;
        right: 2.5rem;
        font-size: 3rem;
        text-align: center;
        background-color: var(--color-secondary);
        border-radius: 3rem;
        color: var(--color-primary);
        cursor: pointer;
        transition: all 300ms ease-in-out;
        z-index: 10;
    }

    .menu-toggle__button {
        text-decoration: none;
        background-color: transparent;
        padding: 0.5rem 1.5rem;
        border: none;
        cursor: pointer;
    }

    .layout__aside-fondo--active {
        display: block;
        position: absolute;
        top: 0;
        right: 32rem;
        background-color: transparent;
        width: 100%;
        height: 100%;
    }

    .layout__aside {
        display: block;
        position: absolute;
        top: 0;
        right: -100%;
        bottom: 0;
        z-index: 10;
        opacity: 0;
        width: 32rem;
        text-align: center;
        overflow: auto;
        border-radius: 0;
        transition: all 200ms ease-in-out;
    }
    
    .layout__aside--active {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        opacity: 1;
        width: 32rem;
        text-align: center;
        overflow: auto;
        border-radius: 0;
        transition: all 300ms ease-in-out;
    }

    .layout__menu {
        position: static;
        margin: 1rem 0rem;   
    }

    .user-info__container-imagen {
        width: 17rem;
        height: 17rem;
        margin-top: 5rem;
    }

    .user-info__name {
        font-size: 2rem;
        margin-top: 1.5rem;
    }

    .user-info__job {
        font-size: 1.7rem;
        margin-top: 0.5rem;
    }

    .menu__list {
        position: static;
        width: 100%;
        background-color: var(--color-secondary);
        box-shadow: none;
        margin-left: 0;
    }

    .menu__icon {
        display: none;
    }

    .menu__overlay {
        position: static;
        opacity: 0.6;
        margin-top: 0;
        padding: 0;
        box-shadow: none;
        font-size: 1.6rem;
    }

    .menu__overlay--active {
        opacity: 1;
    }

    .user-info__links {
        margin-top: 0;
        margin-bottom: 0;
    }

    .links__social {
        margin-top: 0;
    }

    .user-info__buttons {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    .user-info__footer {
        position: static;
        margin-top: 1rem;
        font-size: 1.2rem;
        opacity: 0.7;
        padding-bottom: 2rem;
    }

    .layout__content {
        border-radius: 0;
        background-color: transparent;
        height: 100%;
    }

    .content__page {
        border-radius: 0;
        height: 100vh;
    }

    .certificate__pop-up {
        z-index: 8;
    }
}

@media (max-width: 750px) {
    .skills__left {
        flex-basis: calc(50% - 1.5rem);
    }
    .skills__right {
        flex-basis: calc(50% - 1.5rem);
    }
    .logos-box__imagen {
        margin-top: 2rem;
        width: 7rem;
        height: 8rem;
    }
    .data__icon {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }
    .data__subtitle {
        font-size: 1.3rem;
    }
    .skills__subheader--other {
        margin-top: 6rem;
    }
}

@media (max-width: 650px) {
    .page__name {
        font-size: 4rem;
    }
    .studies__container {
        flex-direction: column;
    }
    .studies__left {
        flex-basis: 100%;
        margin: 0;
        margin-bottom: 2rem;
    }
    .studies__study {
        border: 1.5px solid var(--color-secondary);
        border-radius: 1rem;
    }
    .study__name {
        padding-left: 0.8rem;
    }
    .study__name i {
        display: none;
    }
    .certificates__container {
        flex-direction: column;
    }
    .certificates__certificate {
        flex-basis: 100%;
        margin-bottom: 1rem;
    }
    .gallery__item {
        flex-basis: 50%;
    }
    .gallery__imagen {
        width: 25rem;
    }
    .contact__container {
        flex-direction: column;
        align-items: center;
    }
    .contact__info {
        flex-basis: 100%;
        width: 80%;
    }
    .contact__data {
        width: 100%;
        margin-bottom: 2rem;
        padding: 2rem 1rem;
        text-align: center;
        background-color: var(--color-card);
        border: 1px solid var(--color-title);
    }
    .data__icon {
        font-size: 3rem;
        margin-bottom: 1rem;
    }
    .data__subtitle {
        font-size: 1.6rem;   
    }
    .contact__form-box {
        flex-basis: 100%;
        width: 80%;
        margin-bottom: 5rem;
    }
    .contact__map {
        margin-top: 2rem;
        margin-bottom: 4rem;
        overflow: hidden;
    }
    .map__box {
        border: 0.1rem solid transparent;
        width: 100%;
        margin-top: 0.3rem;
    }
    .form__container {
        flex-direction: column;
    }
    .form__left {
        flex-basis: 100%;
        padding: 0;
    }
    .form__right {
        flex-basis: 100%;
        width: 100%;
        padding: 0;
        margin-top: 1rem;
    }
    .form__group {
        margin-bottom: 3rem;
    }
}

@media (max-width: 600px) {
    .about__header {
        margin-bottom: 1.5rem;
    }
    .about__personal-info {
        flex-direction: column;
    }
    .personal-info__bio {
        flex-basis: 100%;
    }
    .personal-info__data {
        flex-basis: 100%;
        padding: 0;
        margin-top: 2rem;
    }
    .services__container {
        flex-direction: column;
    }
    .services__service {
        flex-basis: 100%;
        margin: 0;
        margin-bottom: 2.5rem;
        padding: 0;
    }
    .skills__container {
        flex-direction: column;
    }
    .skills__left {
        flex-basis: 100%;
        width: 100%;
        margin-right: 0;
    }
    .skills__logos-box {
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
    }
    .logos-box__imagen {
        margin-top: 1rem;
        margin-right: 0rem;
        width: 12rem;
        height: 12rem;
    }
    .skills__right {
        flex-basis: 100%;
        width: 100%;
        margin: 0;
        margin-top: 1rem;
    }
    .skills__other {
        padding-left: 1rem;
    }
    .skills__subheader--other {
        margin-top: 3rem;
    }
}

@media (max-width: 480px) {
    .page__name {
        font-size: 2.5rem;
    }
    .page__job {
        font-size: 2rem;
    }
    .study__name {
        font-size: 1.3rem;
        padding-top: 0.3rem;
    }
    .study__description {
        font-size: 1.2rem;
        padding-bottom: 0.3rem;
    }
    .certificate__pop-up {
        top: 10%;
        width: 100%;
        height: 70%;
    }
    .portfolio__option {
        font-size: 1.3rem;
    }
    .portfolio__gallery {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .gallery__title {
        text-align: center;
    }
    .logos-box__imagen {
        width: 10rem;
        height: 10rem;
    }
    .contact__info {
        width: 100%;
    }
    .contact__form-box {
        width: 100%;
        margin-bottom: 4rem;
    }
    .form__button-container {
        margin-bottom: 2rem;
    }
    .form__button {
        color: var(--color-primary);
        background-color: var(--color-secondary);
    }
}