/* VARIABLES */
:root {
    --color-scrollbar: #D3D3D4;
    --color-background-scrollbar: #EEEEEE;
    --color-scrollbar-hover: darkslategray;
    --color-scrollbar-aside: #FFFFFF;
}

/* SCROLL CHROME */
.content__page::-webkit-scrollbar {
    width: 25px;
    background: transparent;
}

.content__page::-webkit-scrollbar-thumb {
    border: 10px solid transparent;
    box-shadow: none;
    border-radius: 40px;
}

.content__page:hover::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px var(--color-scrollbar);
}

.content__page:hover::-webkit-scrollbar-thumb:hover {
    border: 8px solid transparent;
    box-shadow: inset 0 0 0 10px var(--color-scrollbar-hover);
}

.content__page::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 40px 0px;
}


/* SCROLL CHROME BARRA LATERAL */
.layout__aside::-webkit-scrollbar {
    width: 25px;
    background: var(--color-scrollbar-hover);
}

.layout__aside::-webkit-scrollbar-thumb {
    border: 10px solid transparent;
    box-shadow: none;
    border-radius: 40px;
}

.layout__aside:hover::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px var(--color-scrollbar-aside);
}

.layout__aside:hover::-webkit-scrollbar-thumb:hover {
    border: 8px solid transparent;
}

.layout__aside::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 40px 0px;
}

/* SCROLL MOZILLA */
.content__page {
    scrollbar-width: thin;
    scrollbar-color: var(--color-scrollbar) transparent;
}

/* SCROLL MOZILLA BARRA LATERAL */
.layout__aside {
    scrollbar-width: thin;
    scrollbar-color: var(--color-scrollbar) var(--color-scrollbar-hover);
}