/* DEFINICIÓN DE VARIABLES */
:root {
  --color-primary: #FFFFFF;
  --color-secondary: darkslategray;
  --color-background: #F2F7F9;
  --color-transparent: transparent;
  --color-icon: #B5B6B7;
  --color-title: #222222;
  --color-subtitle: #888888;
  --color-text: #555555;
  --color-card: gainsboro;
}

/* GENERAL */
html {
  font-size: 10px;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Poppins", Helvetica, sans-serif;
  font-size: 1.5rem;
  background-color: gray;
  color: var(--color-text);
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-title);
}

/* IMAGEN DE FONDO */
body {
  background-image: url("../IMG/fondo.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -55px -90px;
}

/* ESTRUCTURA PRINCIPAL */
.layout {
  position: relative;
  min-width: 1290px;
  width: 75%;
  height: 80vh;
  min-height: 80vh;
  margin: 10vh auto;

  display: grid;
  grid-template-areas: "aside content";
  grid-template-columns: 30% 70%;

  /* Sombra dekl layout */
  border-radius: 4rem;
  box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.35);
}

/* BARRA LATERAL */
.layout__aside-fondo {
  display: none;
}

.layout__aside {
  grid-area: aside;
  border-radius: 4rem 0 0 4rem;
  overflow: auto;
  overflow-x: hidden;
}

.aside__user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  min-height: 100%;
  min-width: 30rem;

  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.user-info__container-imagen {
  position: relative;
  display: inline-block;
  width: 22rem;
  height: 22rem;
  margin-top: 7rem;
  z-index: 1;
}

.user-info__imagen {
  width: 100%;
  height: 100%;
  background-color: var(--color-transparent);
  border: 0.2rem solid var(--color-transparent);
  border-radius: 20rem;
}

.user-info__container-imagen::after {
  content: "";
  position: absolute;
  top: 2%;
  left: 7%;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: -webkit-repeating-radial-gradient(center center, #FFFFFF, #FFFFFF 1px, transparent 0px, transparent 100%);
  background-size: 0.6rem 0.6rem;
  opacity: 0.4;
  border-radius: 30rem;
}

.user-info__name {
  font-size: 3rem;
  font-weight: bold;
  color: var(--color-primary);
  margin-top: 2.5rem;
}

.user-info__job {
  font-size: 2rem;
  font-weight: 400;
  color: var(--color-primary);
  margin-top: 1rem;
}

.user-info__links {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.links__social {
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.social__option {
  height: 5rem;
  width: 5rem;
  font-size: 2.5rem;
  line-height: 5.3rem;
  opacity: 0.6;
  border-radius: 3rem;
  transition: all 300ms linear;
  margin: 0rem 0.2rem;
}

.social__option:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.20);
}

.social__link {
  color: var(--color-primary);
}

.user-info__buttons {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.user-info__btn {
  text-decoration: none;
  font-weight: 500;
  padding: 3rem;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border: 2px solid var(--color-primary);
  border-radius: 3.2rem;
  color: var(--color-primary);
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.22);
  transition: all 300ms ease-in-out;
}

.user-info__btn:hover {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.user-info__footer {
  position: static;
  font-size: 1.3rem;
  opacity: 0.7;
  margin-bottom: 2rem;
}

/* CONTENEDOR PRINCIPAL */
.layout__content {
  grid-area: content;
  height: 100%;
  min-height: 100%;
  background-color: var(--color-secondary);
  border-radius: 0 10rem 10rem 0;
}

.content__page {
  min-height: 100%;
  background-color: var(--color-primary);
  border-radius: 3.2rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page__name {
  font-size: 5rem;
  margin-bottom: 2rem;
}

.page__job {
  font-size: 2.5rem;
  font-weight: lighter;
  color: var(--color-subtitle);
}


/* MENU NAVEGACIÓN LATERAl */
.layout__menu {
  display: block;
  position: absolute;
  top: 0;
  right: -125px; 
  min-width: 11rem;
  background-color: var(--color-transparent); 
}

.menu__list {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  min-height: 32rem;
  padding: 2rem 0rem;
  margin-left: 0.5rem;
  background-color: var(--color-primary);
  box-shadow: 0 0 3rem -0.5rem rgba(0, 0, 0, 0.40);
  border-radius: 6rem;
}

.menu__option {
  display: block;
  width: 100%;
  text-align: center;
}

.menu__link {
  display: inline-block;
  border: var(--color-transparent);
  background-color: var(--color-transparent);
}

.menu__link:hover {
  cursor: pointer;
}

/* .menu__link:active {
  scale: 0.9;
} */

.menu__icon {
  color: var(--color-icon);
  font-size: 2.8rem;
  transition: all 300ms ease-in-out;
}

.menu__option:hover .menu__icon{
  color: var(--color-secondary);
}

.menu__icon--active {
  color: var(--color-secondary);
}

.menu__overlay {
  position: absolute;
  right: 0;
  margin-top: -3rem;
  display: block;
  opacity: 0;
  padding: 0.5rem 1rem;
  color: var(--color-primary);
  background-color: var(--color-secondary);
  border-radius: 3rem;
  white-space: nowrap;
  transition: all 300ms ease-in-out;
}

.menu__option:hover .menu__overlay {
  opacity: 1;
  right: 85%;
}


/* ESTILOS GENERALES (SOBRE MI, ESTUDIOS, ETC) */
.content__about,
.content__studies,
.content__portfolio,
.content__skills,
.content__contact {
  display: block;
  height: 100%;
  min-height: 100%;
  padding: 6rem 3rem;
  overflow: auto;
}

.about__header,
.studies__header,
.studies__subheader,
.portfolio__header,
.skills__header,
.skills__subheader,
.contact__header {
  display: inline-block;
  margin-bottom: 4rem;
}

.contact__form-header {
  display: inline-block;
  margin-bottom: 2rem;
}
 
.studies__subheader,
.skills__subheader {
  display: inline-block;
  margin-bottom: 2rem;
}

.skills__subheader--other {
  margin-top: 5.5rem;
}

.about__title,
.studies__title,
.portfolio__title,
.skills__title,
.contact__title {
  font-size: 3.2rem;
  position: relative;
}

.studies__subtitle,
.skills__subtitle,
.contact__subtitle {
  font-size: 2.3rem;
  position: relative;
}

.title__color,
.subtitle__color {
  font-weight: bold;
  color: var(--color-secondary);
}

.about__title::after,
.studies__title::after,
.portfolio__title::after,
.skills__title::after,
.contact__title::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 30px;
  top: 17px;
  right: -18px;
  background-image: -webkit-repeating-radial-gradient(center center, var(--color-secondary), var(--color-secondary) 1px, transparent 0px, transparent 100%);
  background-size: 6px 6px;
  opacity: 0.5;
}

.studies__subtitle::after,
.skills__subtitle::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 25px;
  top: 12px;
  right: -15px;
  background-image: -webkit-repeating-radial-gradient(center center, var(--color-secondary), var(--color-secondary) 1px, transparent 0px, transparent 100%);
  background-size: 6px 6px;
  opacity: 0.5;
}

.contact__subtitle::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 25px;
  top: 11px;
  right: -13px;
  background-image: -webkit-repeating-radial-gradient(center center, var(--color-secondary), var(--color-secondary) 1px, transparent 0px, transparent 100%);
  background-size: 6px 6px;
  opacity: 0.5;
}

/* SOBRE MI */
/* INFORMACIÓN PERSONAL */
.about__personal-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5rem;
}

.personal-info__bio {
  flex-basis: 55%;
}

.personal-info__description {
  font-size: 1.5rem;
  line-height: 2.4rem;
  text-align: justify;
}

.personal-info__data {
  flex-basis: 45%;
  text-align: left;
  padding-left: 6rem;
}

.personal-info__option {
  margin-bottom: 0.3rem;
}

.personal-info__title {
  color: var(--color-secondary);
  font-weight: 600;
  margin-right: 0.7rem;
}

/* SERVICIOS */
.about__services {
  width: 100%;
} 

.services__header {
  display: inline-block;
  padding-right: 1.2rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.services__title::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 20px;
  top: 15px;
  right: 0px;
  background-image: -webkit-repeating-radial-gradient(center center, var(--color-secondary), var(--color-secondary) 1px, transparent 0px, transparent 100%);
  background-size: 6px 6px;
  opacity: 0.5;
}

.services__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.services__service {
  flex-basis: calc(50% - 3rem);
  margin-right: 3rem;
  margin-bottom: 2rem;
  padding: 0rem 1rem;
}

.service__title {
  font-size: 1.8rem;
  margin: 0.5rem 0rem;
}

.service__icon {
  color: var(--color-secondary);
  font-size: 3rem;
}

.service__description {
  font-size: 1.5rem;
  text-align: justify;
}

/* ESTUDIOS */
.studies__container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.studies__left {
  flex-basis: calc(60% - 1.5rem);
  margin-right: 1.5rem;
}

.studies__right {
  flex-basis: 40%;
}

.studies__study {
  margin-bottom: 1.5rem;
}

.study__name {
  font-size: 1.5rem;
}

.study__name i {
  color: var(--color-secondary);
}

.study__description {
  font-size: 1.5rem;
  padding: 0rem 1.5rem;
}

.studies__certificates {
  margin-top: 3rem;
}

.certificates__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  gap: 2rem;
}

.certificates__certificate {
  flex-basis: calc(50% - 2rem);
  margin-bottom: 3rem;
  border: 2px solid var(--color-icon);
  border-radius: 1.5rem;
  padding: 0.5rem 0.5rem;
}

.certificate__imagen {
  border: 2px solid transparent;
  border-radius: 1rem;
}

.certificate__imagen:hover {
  cursor: pointer;
  border-color: var(--color-secondary);
}

.certificate__title {
  padding: 0rem 1.5rem;
  padding-top: 0.5rem;
}

.certificate__temas {
  padding: 0rem 1.5rem;
}

.certificate__pop-up {
  position: fixed;
  top: 5%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  border: solid 5px transparent;
}

.certificate__pop-up:hover {
  cursor: pointer;
  border-color: var(--color-secondary);
}

/* DEV SKILLS */
.skills__container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.skills__left {
  flex-basis: calc(45% - 1.5rem);
  margin-right: 1.5rem;;
}

.skills__right {
  flex-basis: calc(55% -1.5rem);
  margin-left: 1.5rem;
}

.skills__logos-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  margin-left: 1rem;
}

.logos-box__last {
  margin-bottom: 3rem;
}

.logos-box__imagen {
  width: 14rem;
  height: 14rem;
  margin-right: 2.5rem;
}

.skills__skill-level {
  width: 100%;
  margin-bottom: 3rem;
  padding: 0 1rem;
}

.skill-level__title {
  display: flex;
  justify-content: space-between;
}

.skill-level__text {
  font-size: 1.3rem;
  line-height: 1.4rem;
  margin-bottom: 0.4rem;
}

.skill-level__number {
  font-size: 1.1rem;
  line-height: 1.4rem;
  margin-bottom: 0.4rem;
  color: var(--color-subtitle);
}

.skill-level__progressbar {
  width: 100%;
  height: 1rem;
  border: 1px solid var(--color-secondary);
  border-radius: 1.5rem;
  background-color: var(--color-primary);
  margin-bottom: 1rem;
}

.skill_level__percentage {
  height: 0.8rem;
  background-color: var(--color-secondary);
  border: 2px solid var(--color-primary);
  border-radius: 1.5rem;
}

/* MODIFICADORES VALOR BARRA DE PORCENTAJE */
.skill_level__percentage--p1 {width: 80%;}

.skill_level__percentage--p2 {width: 95%;}

.skill_level__percentage--p3 {width: 90%;}

.skill_level__percentage--p4 {width: 85%;}

.skill_level__percentage--p5 {width: 75%;}

.skill_level__percentage--p6 {width: 60%;}
/*----------------------------------------*/

.skills__other {
  width: 100%;
}

.other__ability {
  font-size: 1.3rem;
  margin-bottom: 1.3rem;
}

.other__name {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--color-title);
}

/* PORTFOLIO */
.portfolio__navbar {
  margin-bottom: 3rem;
}

.portfolio__menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.portfolio__option {
  font-size: 1.5rem; 
}

.portfolio__link {
  background-color: transparent;
  border: none;
  padding: 0.5rem 0.5rem;
  opacity: 0.7;
}

.portfolio__link:hover {
  cursor: pointer;
  color: var(--color-secondary);
  font-weight: bold;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
}

.portfolio__link--active {
  color: var(--color-secondary);
  font-weight: bold;
  opacity: 1;
}

.portfolio__gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.gallery__item {
  position: relative;
  flex-basis: 33%;
  padding: 0.7rem;
  margin-bottom: 2rem;
}

.gallery__container-imagen {
  overflow: hidden;
}

.gallery__imagen {
  width: 30rem;
  height: 30rem;
  transition: all 300ms ease-in-out;
  padding: 1.5rem;
  border: 1px solid var(--color-subtitle);
  border-radius: 2rem;
}

.gallery__item:hover .gallery__imagen {
  transform: scale(1.1);
}

.gallery__title {
  font-size: 1.6rem;
  padding: 1rem 1.5rem;
  font-weight: bold;
}

.gallery__icon {
  opacity: 0;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  background-color: var(--color-secondary);
  color: var(--color-primary);
  border-radius: 1rem;
  transition: all 800ms ease-in-out;
}

.gallery__category {
  opacity: 0;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0rem 1rem;
  background-color: var(--color-secondary);
  color: var(--color-primary);
  border-radius: 1rem;
  transition: all 800ms ease-in-out;
}

.gallery__item:hover .gallery__icon,
.gallery__item:hover .gallery__category {
  opacity: 1;
}

/* CONTACTO */
.contact__container {
  display: flex;
  flex-direction: row;
}

.contact__info {
 flex-basis: 33%;
 padding: 0 1.5rem;
}

.contact__data {
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 2rem 1rem;
  text-align: center;
  background-color: var(--color-card);
  border: 1px solid var(--color-title);
}

.data__link {
  text-decoration: none;
  color: var(--color-secondary);
}

.data__link:hover {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}

.data__icon {
  color: var(--color-secondary);
  font-size: 4rem;
  margin-bottom: 0.5rem;
}

.data__subtitle {
  color: var(--color-secondary);
  font-size: 2rem;
}

.contact__map {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 15rem;
  margin-bottom: 3rem;
  overflow: hidden;
}

.map__box {
  border: 0.2rem solid transparent;
  border-radius: 0.5rem;
  width: 90%;
  margin-top: 0.3rem;
}

/* Pantalla de carga para mapa */
.map__loader-container {
  height: 10%;
  width: 100%;
  position: relative;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 1;
  margin-top: 1rem;
}

.map__loader {
  border: 10px solid var(--color-secondary);
  border-top-color: var(--color-subtitle);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-animation: girar 1.0s linear infinite;
  -o-animation: girar 1.0s linear infinite;
  animation: girar 1.0s linear infinite;
}

@keyframes girar {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.contact__form-box {
  flex-basis: 66%;
  padding: 0 1.5rem;
}

.form__container {
  display: flex;
  flex-direction: row;
}

.form__left {
  flex-basis: 45%;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
}

.form__group {
  position: relative;
  margin-bottom: 2.8rem;
}

.form__input {
  position: relative;
  font-size: 1.5rem;
  width: 100%;
  height: 4rem;
  display: block;
  border: 0.2rem solid var(--color-secondary);
  border-radius: 0.5rem;
  padding: 0.3rem 0.8rem;
  margin-bottom: 2rem;
  outline: none;
  box-shadow: 0 1rem 1rem -8px rgba(0, 0, 0, 0.12);
  background-color: transparent;
  resize: none;
}

.form__input:focus {
  border: 0.2rem solid lightseagreen;
}

.form__label {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  color: var(--color-subtitle);
  transition: all 300ms ease-in-out;
  padding: 0.1rem 0.3rem;
  pointer-events: none;
}

.form__input:focus~label,
.form__input:not(:placeholder-shown)~label {
  top: -2rem;
  left: 0rem;
  z-index: 1;
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--color-secondary);
}

/* Ocultar placeholder en navegadores con webkit */
.form__input::-webkit-input-placeholder {
  color: transparent;
}

/* Ocultar placeholder en mozilla */
.form__input::-moz-placeholder {
  color: transparent;
}

.form__right {
  flex-basis: 55%;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}

.form__input--textarea {
  display: block;
  height: 16rem;
  resize: none;
}

.form__button {
  padding: 1rem 2rem;
  font-size: 1.5rem;
  border: 0.2rem solid var(--color-secondary);
  color: var(--color-title);
  background-color: var(--color-primary);
  border-radius: 3rem;
  box-shadow: 0 1rem 1rem -8px rgba(0, 0, 0, 0.22);
  transition: all 300ms linear;
}

.form__button-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.form__button:hover {
  cursor: pointer;
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.mensaje__envio {
  margin-top: 0.5rem;
  font-weight: 600;
}

